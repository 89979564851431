import { render, staticRenderFns } from "./Spotlight.vue?vue&type=template&id=4b43583c&scoped=true&"
import script from "./Spotlight.vue?vue&type=script&lang=js&"
export * from "./Spotlight.vue?vue&type=script&lang=js&"
import style0 from "./Spotlight.vue?vue&type=style&index=0&id=4b43583c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b43583c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsSpotlight: require('/home/ticketshoplv/public_html/jarmolenka.tour.tickets/components/elements/Spotlight.vue').default,ElementsHero: require('/home/ticketshoplv/public_html/jarmolenka.tour.tickets/components/elements/Hero.vue').default})
