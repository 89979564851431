import { render, staticRenderFns } from "./index.vue?vue&type=template&id=72430fb2&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TemplatesPagesWidgetDatepicker: require('/home/ticketshoplv/public_html/jarmolenka.tour.tickets/components/templates/pages/widget-datepicker.vue').default,TemplatesPagesWidget: require('/home/ticketshoplv/public_html/jarmolenka.tour.tickets/components/templates/pages/widget.vue').default,TemplatesPagesGroup: require('/home/ticketshoplv/public_html/jarmolenka.tour.tickets/components/templates/pages/group.vue').default,TemplatesPagesHome: require('/home/ticketshoplv/public_html/jarmolenka.tour.tickets/components/templates/pages/home.vue').default})
